import Vue from "vue";
import Vuex from "vuex";
import Axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
    strict: true,
    state: {
        showContactModal: false
    },
    mutations: {
        _toggleContactModal(state){
            state.showContactModal = !state.showContactModal;
        }
    },
    actions: {
        async contactArvore(context, fdata){
            let services = "";
            for(let i = 0; i < fdata.services.length; i++){
                services += fdata.services[i];
                if(fdata.services.length != i+1){
                    services += ", ";
                }
            }
            fdata.services = services;
            let response = await Axios.post('https://api.arvoreglobalcorp.com/api/v1/contact-arvore', fdata);
            return response;
        }
    }
})
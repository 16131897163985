<template>
  <div id="app">
    <router-view :class="$route.path == '/' ? '' : 'mt-5 pt-5'" />
  </div>
</template>

<script>
  export default {
    name: 'app'
  }
</script>
<style>
  @import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800,900|Raleway:300,400,500,600,700,800,900");
  @import url("https://fonts.googleapis.com/css?family=Oswald:300,400,500,600,700,800,900|Raleway:300,400,500,600,700,800,900");

  .ar-text {
    color: #11619d;
  }

  .ar-main-title {
      padding-bottom: 20px;
      font-size: 40px;
      font-weight: 700;
      text-align: center;
      font-family: Raleway;
      color:#11619d;
  }

  .btn-arvore {
        background-color: #11619d !important;
        color: white !important;
    }

  .btn-arvore:hover {
      color: white;
  }

  .hili-text {
    font-weight: 400;
    font-size: 20px;
    margin: auto;
    hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    font-family: Open Sans;
  }

  .img-mission {
    border-radius: 10px;
  }

  .ar-checkbox label {
    font-size: .9rem;
    cursor: pointer;
    color:#9e9e9e;
  }
  .ar-checkbox {
    display: inline-block;
    padding-right: 1rem;
  }

  .ar-checkbox label p {
    margin-left: 1.5rem;
    margin-top: 0rem;
  }

  .check-custom {
    position: absolute;
    display: inline;
    height: 1.2rem;
    width: 1.2rem;
    background-color: transparent;
    border-radius: .2rem;
    transition: all .2s ease-out;
    -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    -ms-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    border: .2rem solid #9e9e9e;
  }

  .check-custom::after {
      position: absolute;
      content: "";
      left: .8rem;
      top: .8rem;
      height: 0;
      width: 0;
      border-radius: .2rem;
      border-width: 0 .3rem .3rem 0;
      -webkit-transform: rotate(0deg) scale(0);
      -ms-transform: rotate(0deg) scale(0);
      transform: rotate(0deg) scale(0);
      opacity: 1;
      transition: all .2s ease-out;
      -webkit-transition: all .2s ease-out;
      -moz-transition: all .2s ease-out;
      -ms-transition: all .2s ease-out;
      -o-transition: all .2s ease-out;
  }

  .ar-checkbox input {
    display: none;
  }

  .ar-checkbox input:checked ~ label .check-custom {
      border: .2rem solid #11619d;
      opacity: 1;
      -webkit-transform: rotate(0deg) scale(1);
      -ms-transform: rotate(0deg) scale(1);
      transform: rotate(0deg) scale(1);
  }

  .ar-checkbox input:checked ~ label {
    color: #11619d;
  }
      
  .ar-checkbox input:checked ~ label .check-custom::after {
      -webkit-transform: rotate(45deg) scale(1);
      -ms-transform: rotate(45deg) scale(1);
      transform: rotate(45deg) scale(1);
      opacity:1;
      left: .3rem;
      top: .01rem;
      width: .3rem;
      height: .7rem;
      border: solid rgb(19, 64, 77);
      border-width: 0 .1rem .1rem 0;
      background-color: transparent;
      border-radius: 0;
  }
  .color-arvore {
    color: rgb(19, 64, 77);
  }

  .arvore-client {
    width: 40%; 
    border-radius: 10px;
    height: auto;
    padding: 20px;
    margin: 20px;
    background-color: white;
    box-shadow: 0 2rem 4rem rgba(#000, .2);
  }

  .carousel-control-next-icon, .carousel-control-prev-icon {
    width: 80px !important;
    height: 80px !important;
}

  @media (max-width: 1200px) {
      
  }
  
  @media (max-width: 992px) {

  }
  
  @media (max-width: 768px) {
      .hili-text {
          padding-top: 20px;
          padding-bottom: 20px;
      }
      .ar-main-title{
        font-size: 30px;
      }
  }

  @media (max-width: 576px) {
      .hili-text {
          max-width: 80%;
      }
      
      .img-mission {
        max-width: 80% !important;
      }
  }
</style>
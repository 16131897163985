import Vue from 'vue'
import App from './App.vue'

import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css'

//import 'font-awesome/css/font-awesome.min.css';

import Vuelidate from "vuelidate";

import router from './router';
import store from './store';
//import './registerServiceWorker';


Vue.use(Vuelidate);

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')

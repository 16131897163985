import Vue from "vue";
import VueRouter from "vue-router";

const Main = () => import('../components/Main');
const Clients = () => import('../components/Clients');
const Services = () => import('../components/Services');
const MissionVision = () => import('../components/MissionVision');
const TeamMain = () => import('../components/TeamMain');
const AboutUs = () => import('../components/AboutUs');
const Gallery = () => import('../components/Gallery');


Vue.use(VueRouter);

export default new VueRouter({
    mode: "history",
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
    routes: [
        { path: "/", component: Main },
        { path: "/clients", component: Clients },
        { path: "/services", component: Services },
        { path: "/mission-vision", component: MissionVision },
        { path: "/team", component: TeamMain },
        { path: "/gallery", component: Gallery },
        { path: "/about-us", component: AboutUs }
    ]
});